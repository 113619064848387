import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import classNames from 'classnames';

import Particles from '../Particles/Particles';
import { Container } from '../Grid';
import Logo from '../Logo/Logo';

import './Menu.scss';

export default ({ isOpen }) => {
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'visible';
    });

    return (
        <div
            className={classNames({
                menu: true,
                'menu--open': isOpen,
            })}
        >
            {isOpen && <Particles color="#ffffff" />}
            <div className="menu__main">
                <Container>
                    <div className="menu__logo">
                        <Link to="/">
                            <Logo to="/" color="white" />
                        </Link>
                    </div>
                    <ul className="menu__menu">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/expertise/">Expertise</Link>
                        </li>
                        <li>
                            <Link to="/who/">Who</Link>
                        </li>
                        <li>
                            <Link to="/what/">What</Link>
                        </li>
                        <li>
                            <Link to="/where/">Where</Link>
                        </li>
                        <li>
                            <Link to="/contact/">Contact</Link>
                        </li>
                    </ul>
                </Container>
            </div>
        </div>
    );
};
