import React, { useState } from 'react';

import Footer from '../Footer/Footer';
import Menu, { MenuButton } from '../Menu';
import Particles from '../Particles/Particles';

import '../../sass/main.scss';
import './Layout.scss';

export default ({ children }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    return (
        <div className="layout">
            <MenuButton isOpen={menuIsOpen} setIsOpen={setMenuIsOpen} />
            <Menu isOpen={menuIsOpen} />
            <main role="main">
                {!menuIsOpen && <Particles />}
                <div className="layout__main">{children}</div>
            </main>
            <Footer />
        </div>
    );
};
