import React from 'react';

import './PageHeader.scss';

export default ({ as, children }) => {
    const Heading = as || 'h2';
    return (
        <div className="page-header">
            <Heading className="page-header__heading">{children}</Heading>
        </div>
    );
};
