import React from 'react';
import { FaBars } from 'react-icons/fa';
import classNames from 'classnames';

import './MenuButton.scss';

export default ({ setIsOpen, isOpen }) => (
    <button
        className={classNames({
            'menu-button': true,
            'menu-button--open': isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
    >
        <FaBars />
    </button>
);
