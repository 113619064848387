import React from 'react';
import classNames from 'classnames';

import './Typography.scss';

export default ({ children, color }) => {
    return (
        <div
            className={classNames({
                typography: true,
                [`typography--${color}`]: !!color,
            })}
        >
            {children}
        </div>
    );
};
