import React from "react";
import classNames from "classnames";

import "./Container.scss";

const Container = ({ children, size }) => {
    const classes = classNames({
        container: true,
        [`container--${size}`]: size,
    });
    return <div className={classes}>{children}</div>;
};

export default Container;
