import { Link } from 'gatsby';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { useSiteMetadata } from '../../queries';
import { Container } from '../Grid';
import Logo from '../Logo/Logo';

import './Footer.scss';

export default () => {
    const { title, author } = useSiteMetadata();
    return (
        <footer className="footer" role="contentinfo">
            <div className="footer__main">
                <Container>
                    <div className="footer__logo">
                        <Logo />
                    </div>
                </Container>
            </div>
            <div className="footer__bottom">
                <Container>
                    <p className="footer__copyright">
                        &copy; Copyright {new Date().getFullYear()} {title} | <Link to="/privacy/">Privacy Policy</Link>
                    </p>
                    <p className="footer__indigotree">
                        Built by{' '}
                        <OutboundLink href={author.url} target="_blank" rel="noopener noreferrer">
                            {author.name}
                        </OutboundLink>
                    </p>
                </Container>
            </div>
        </footer>
    );
};
