import React from 'react';

import './Particles.scss';

export default ({ color = '#C7C7C7' }) => {
    return (
        <div className="particles">
            <div className="particles__item">
                {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16265.4 16138.5">
                    <path
                        fill="none"
                        stroke={color}
                        strokeWidth="3"
                        strokeMiterlimit="5.11"
                        d="M278.6 9150.6l4767.4-3444 4797.3 3442M9848.3 15028.8l-5-5881.3L15441 7269.7M5046 5706.6V-282.2"
                    />
                    <circle fill={color} cx="5046" cy="5706.6" r="41.2" />
                    <circle fill={color} cx="9843.3" cy="9150.6" r="41.2" />
                </svg>*/}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16312.9 16297.1">
                    <path
                        fill="none"
                        stroke={color}
                        strokeWidth="1.51"
                        strokeMiterlimit="2.57"
                        d="M-58.8 11728l6637.4-4800.8 2416.1 1733.5M8997.2 16308.3l-2.5-7648.1 7329.5-2464.9M6578.6 6927.2v-6986"
                    />
                    <circle fill={color} cx="6578.6" cy="6927.2" r="20.8" />
                    <circle fill={color} cx="8994.7" cy="8661.7" r="20.8" />
                </svg>
            </div>
        </div>
    );
};
