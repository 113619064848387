import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import { Container } from '../Grid';
import './Hero.scss';
import Logo from '../Logo/Logo';
import { useMediaQuery } from 'react-responsive';

const Hero = ({ title, mobileImage, desktopImage, logoColor, logoPosition }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <div className="hero">
            <div
                className="hero__image"
                style={{
                    backgroundImage: `url(${
                        isMobile ? mobileImage.childImageSharp.fluid.src : desktopImage.childImageSharp.fluid.src
                    })`,
                }}
            />
            <div className="hero__content">
                <div
                    className={classNames({
                        hero__logo: true,
                        [`hero__logo--${logoPosition}`]: !!logoPosition,
                    })}
                >
                    <Link to="/">
                        <Logo color={logoColor} />
                    </Link>
                </div>
                {title && (
                    <Container>
                        <h1 className="hero__title">{title}</h1>
                    </Container>
                )}
            </div>
        </div>
    );
};

Hero.defaultProps = {
    logoPosition: 'center',
    logoColor: 'black',
};

export default Hero;

export const query = graphql`
    fragment HeroBackgroundImage_Desktop on File {
        childImageSharp {
            fluid(maxWidth: 1920) {
                src
            }
        }
    }
    fragment HeroBackgroundImage_Mobile on File {
        childImageSharp {
            fluid(maxWidth: 767) {
                src
            }
        }
    }
`;
