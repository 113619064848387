import React from 'react';
import Helmet from 'react-helmet';
import { useSiteMetadata } from '../queries';

const SEO = ({ title, description = '', meta = [], ...rest }) => {
    const siteMetadata = useSiteMetadata();
    return (
        <Helmet
            htmlAttributes={{ lang: 'en-gb' }}
            title={title}
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ].concat(meta)}
            {...rest}
        />
    );
};

export default SEO;
