import React from 'react';
import classNames from 'classnames';

import './Band.scss';

const Band = ({ children, size, background, contained = true }) => {
    const classes = classNames({
        band: true,
        [`band--${size}`]: size,
        [`band--${background}`]: background,
        'band--contained': !!contained,
    });
    return (
        <div className={classes}>
            <div className="band__body">{children}</div>
        </div>
    );
};

export default Band;
